import React from "react";

function Hero() {
  return (
    <section className="bg-orange-500 text-white py-20">
      <div className="container mx-auto px-4 text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">
          Specialized Emergency Medical Services for Trail Races
        </h1>
        <p className="text-xl mb-8">
          TrackMed offers tailored medical support for trail races and
          ultramarathons
        </p>
        <button className="bg-white text-orange-500 hover:bg-orange-100 px-6 py-2 rounded-md font-semibold">
          Learn More
        </button>
      </div>
    </section>
  );
}

export default Hero;
