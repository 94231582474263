import React from "react";
import { MapPin, Mail } from "lucide-react";

function Contact() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Contact Us</h2>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <MapPin className="h-6 w-6 text-orange-500 mr-2" />
              <h3 className="text-xl font-semibold">Our Address</h3>
            </div>
            <p className="text-gray-600">
              123 Trail Runner Road
              <br />
              Mountain View, CA 94041
              <br />
              United States
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <Mail className="h-6 w-6 text-orange-500 mr-2" />
              <h3 className="text-xl font-semibold">Email Us</h3>
            </div>
            <p className="text-gray-600">
              General Inquiries:{" "}
              <a
                href="mailto:info@trackmed.com"
                className="text-orange-500 hover:underline"
              >
                info@trackmed.com
              </a>
              <br />
              Support:{" "}
              <a
                href="mailto:support@trackmed.com"
                className="text-orange-500 hover:underline"
              >
                support@trackmed.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
